.awareness-page {
    background-color: #FFF7FF;
    min-height: 100vh;
    padding-bottom: 10px;
    background-size: cover;
    background-repeat: no-repeat;
  }
  .awareness-page .image {
    max-width: 375px;
    margin: auto;
  }
  
  .awareness-page .image img {
    width: 100%;
  }
  
  @media (max-width: 767px){
    .awareness-page {
      background-color: #fff;
    }
  }
  